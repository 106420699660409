<script>
import { mapActions } from 'vuex'

export default {
  name: 'ThematicPagesList',
  components: {
    Action: () => import('@/components/general/Action'),
    Dropdown: () => import('@/components/general/Dropdown'),
    Datatable: () => import('@/components/general/Datatable'),
    FilterList: () => import('@/components/general/FilterList'),
    Pagination: () => import('@/components/general/Pagination'),
    ModalConfirm: () => import('@/components/macro/ModalConfirm'),
    DropdownLink: () => import('@/components/general/DropdownLink'),
    ContentHeader: () => import('@/components/general/ContentHeader'),
    FilterListSearch: () => import('@/components/general/FilterListSearch')
  },

  data () {
    return {
      pagination: {
        page: 1,
        lastPage: null
      },

      modalRemoveOpen: false,
      thematicPageId: null
    }
  },

  computed: {
    thematicPagesList () {
      return this.$store.getters.thematicPagesList
    }
  },

  watch: {
    'pagination.page' () {
      this.getThematicPageList()
    }
  },

  created () {
    this.getThematicPageList()
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptGetThematicPagesList',
      'attemptRemoveThematicPage',
      'attemptToggleActiveThematicPage'
    ]),

    prevPage () {
      if (this.pagination.page > 1) {
        this.pagination.page -= 1
      }
    },

    nextPage () {
      this.pagination.page += 1
    },

    firstPage () {
      this.pagination.page = 1
    },

    lastPage () {
      this.pagination.page = this.pagination.lastPage
    },

    getThematicPageList () {
      this.setFetching(true)

      this.pagination.order = {
        active: 'desc',
        name: 'asc'
      }

      this.attemptGetThematicPagesList(this.pagination).then(pagination => {
        this.pagination.lastPage = pagination.lastPage
      }).finally(() => {
        this.setFetching(false)
      })
    },

    createNewThematicPage () {
      this.$router.push({ name: 'thematicPages.manage' })
    },

    searchThematicPage (search) {
      this.pagination.query = {
        name: search
      }

      this.getThematicPageList()
    },

    edit (thematicPageId, tab = 1) {
      this.$router.push({
        name: 'thematicPages.manage',
        params: {
          id: thematicPageId,
          tab: tab
        }
      })
    },

    remove () {
      this.modalRemoveOpen = false
      this.setFetching(true)

      this.attemptRemoveThematicPage(this.thematicPageId).then(() => {
        this.setFeedback({ message: this.$t('thematicPages:feedback.remove.success') })
        this.getThematicPageList()
      }).catch(() => {
        this.setFeedback({ message: this.$t('thematicPages:feedback.remove.error') })
      }).finally(() => {
        this.setFetching(false)
      })
    },

    confirmRemove (thematicPageId) {
      this.thematicPageId = thematicPageId
      this.modalRemoveOpen = true
    },

    cancelRemove () {
      this.modalRemoveOpen = false
    },

    openPage (track) {
      window.open(`${process.env.VUE_APP_PORTAL_URL}${track.thematicPageType.slug}/${track.slug}`, '_blank')
    },

    toggleActive (thematicPageId) {
      this.setFetching(true)

      this.attemptToggleActiveThematicPage(thematicPageId).then((response) => {
        this.setFeedback({
          message: response.data.active
            ? this.$t('thematicPages:feedback.toggle.activate.success')
            : this.$t('thematicPages:feedback.toggle.deactivate.success')
        })

        this.getThematicPageList()
      }).catch(() => {
        this.setFeedback({ message: this.$t('global.error') })
      }).finally(() => {
        this.setFetching(false)
      })
    }
  }
}
</script>

<template>
  <div class="main-content">
    <ContentHeader
      :title="$t('thematicPages:header.title')"
      :description="$t('thematicPages:header.description')"
      background="/assets/images/themes/default/png/partners_banner.png"
    />
    <FilterList>
      <Action
        slot="button"
        primary
        large
        fixed-width
        type="button"
        :text="$t('thematicPages.list:btn.add')"
        @click="createNewThematicPage"
      />
      <FilterListSearch
        slot="search"
        :hide-error-details="true"
        @search="searchThematicPage"
      />
    </FilterList>
    <div
      v-if="thematicPagesList.length > 0"
      class="center thematic-pages-table"
    >
      <Datatable :items="thematicPagesList">
        <template slot="thead">
          <tr>
            <th class="th">
              {{ $t('thematicPages.list:datatable.header.1') }}
            </th>
            <th
              class="th"
              width="200"
            >
              {{ $t('thematicPages.list:datatable.header.2') }}
            </th>
            <th
              class="th"
              width="200"
            >
              {{ $t('thematicPages.list:datatable.header.3') }}
            </th>
            <th
              class="th"
              width="40"
            />
          </tr>
        </template>
        <template
          slot="tbody"
          slot-scope="props"
        >
          <tr>
            <td class="td">
              <span class="td-text bolder">{{ props.item.name }}</span>
            </td>
            <td class="td">
              <span class="td-text bolder">{{ props.item.thematicPageType.name }}</span>
            </td>
            <td class="td">
              <span
                v-if="!!props.item.active"
                class="td-tag active-ribbon"
              >{{ $t('global.status:active') }}</span>
              <span
                v-else
                class="td-tag inactive-ribbon"
              >{{ $t('global.status:inactive') }}</span>
            </td>
            <td
              class="td td-actions"
              width="40"
            >
              <Dropdown
                icon="dots-vertical"
                right
              >
                <DropdownLink
                  :text="$t('global:edit')"
                  @click="edit(props.item.id)"
                />
                <DropdownLink
                  :text="$t('global:links')"
                  @click="edit(props.item.id, 3)"
                />
                <DropdownLink
                  :text="$t('global:show.page')"
                  @click="openPage(props.item)"
                />
                <DropdownLink
                  v-if="!props.item.active"
                  :text="$t('global:activate')"
                  @click="toggleActive(props.item.id, props.item.active)"
                />
                <DropdownLink
                  v-else
                  :text="$t('global:deactivate')"
                  @click="toggleActive(props.item.id, !props.item.active)"
                />
                <DropdownLink
                  :text="$t('global:remove')"
                  @click="confirmRemove(props.item.id, props.index)"
                />
              </Dropdown>
            </td>
          </tr>
        </template>
      </Datatable>
      <Pagination
        :active-page="pagination.page"
        :page-count="pagination.lastPage"
        @firstPage="firstPage"
        @lastPage="lastPage"
        @nextPage="nextPage"
        @previousPage="prevPage"
      />
    </div>
    <ModalConfirm
      :active="modalRemoveOpen"
      :title="$t('thematicPages.manage:remove.title')"
      :description="$t('thematicPages.manage:remove.description')"
      @confirmAction="remove"
      @cancelAction="cancelRemove"
    />
  </div>
</template>
